'use client';

import EmojiButton from '@/components/Button/EmojiButton';
import Section from '@/components/Section/Section';
import { Persona, getPersonaSlug } from '@/resources/personas/persona';
import { capitalizeFirstLetter } from '@/utils/strings';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';

const PersonaCard = ({
	persona,
	headingLevel,
}: {
	persona: Persona;
	headingLevel: 'h2' | 'h3' | 'h4';
}) => {
	const HeadingComponent = headingLevel;
	const router = useRouter();

	const [isClient, setIsClient] = useState(false);

	useEffect(() => {
		setIsClient(true);
	}, []);

	if (!isClient) {
		return null;
	}

	return (
		<Section
			as="article"
			className="grid w-full grid-cols-3 gap-2 rounded-lg bg-opacity-95 p-2 after:rounded-lg dark:bg-tertiary-950"
		>
			<Image
				src={persona.image.src}
				alt={persona.image.alt}
				placeholder="blur"
				className="aspect-[1/1.5] max-h-56 w-full cursor-pointer rounded-lg object-cover"
				width={150}
				height={300}
				onClick={() =>
					router.push(`/personas/${getPersonaSlug(persona)}`)
				}
			/>
			<div className="col-span-2 flex flex-col gap-2 p-1">
				<Link
					href={`/personas/${getPersonaSlug(persona)}`}
					aria-label={`${persona.name} - ${persona.age} jaar - ${persona.disabilities
						.map((disability) => disability.value)
						.join(', ')}.`}
				>
					<div className="group flex flex-col gap-1">
						<HeadingComponent className="flex items-center gap-2 text-xl text-tertiary-700 dark:text-tertiary-100">
							<span className="group-hover:underline">
								{persona.name}
							</span>{' '}
							<span className="text-lg text-tertiary-500 dark:text-tertiary-300">
								{persona.age} jaar
							</span>
						</HeadingComponent>
						<p className="line-clamp-5 text-base font-semibold md:line-clamp-2">
							{persona.biography}
						</p>
					</div>
				</Link>
				<ul
					className="flex flex-wrap gap-2"
					aria-label={`Beperkingen van ${persona.name}`}
				>
					{persona.disabilities.map((disability) => (
						<li key={disability.value}>
							<EmojiButton
								url={`/wie/functievariatie/${disability.category}`}
								label={capitalizeFirstLetter(disability.value)}
								colorVariant="tertiary"
							/>
						</li>
					))}
				</ul>
			</div>
		</Section>
	);
};

export default PersonaCard;
