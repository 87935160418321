import { NavigationItem, Quiz } from '@/components/Navigation/NavigationItem';
import useLocalStorage from '@/hooks/useLocalStorage';
import { useMemo } from 'react';

export interface PageProgress {
	path: string;
	added_at: string;
}

export interface QuizProgress {
	id: string;
	path: string;
	selected_option_index: number;
	is_correct: boolean;
	added_at: string;
}

export interface ChecklistProgress {
	id: string;
	path: string;
	selected_option_indexes: number[];
	added_at: string;
}

export interface ChapterProgress {
	pages: PageProgress[];
	quizzes?: QuizProgress[];
	checklists?: ChecklistProgress[];
}

const defaultChapterProgress: ChapterProgress = {
	pages: [],
	quizzes: [],
	checklists: [],
};

// Get chapter progress from all child pages recursively
export const useChapterProgress = (
	chapterId: string,
	chapterNavigationTree: NavigationItem,
) => {
	const [chapterProgress, setChapterProgress] =
		useLocalStorage<ChapterProgress>(
			`chapterProgress-${chapterId}`,
			defaultChapterProgress,
		);

	const addPageToChapterProgress = (pageId: string) => {
		const pages = [
			...chapterProgress.pages,
			{
				path: pageId,
				added_at: new Date().toISOString(),
			},
		];
		setChapterProgress({ ...chapterProgress, pages });
	};

	const removePageFromChapterProgress = (path: string) => {
		const pages = chapterProgress.pages.filter(
			(page) => page.path !== path,
		);
		setChapterProgress({ ...chapterProgress, pages });
	};

	const isPageCompleted = (path: string) => {
		return chapterProgress.pages.some((page) => page.path == path);
	};

	const pagesProgressPercentage = useMemo(() => {
		// Get total pages & sub-pages recursively
		const countPagesAndProgress = (
			navigationItem: NavigationItem,
		): {
			count: number;
			progress: number;
		} => {
			const pagePath = [
				navigationItem.parentPath ?? '/',
				navigationItem.name,
			]
				// Remove empty path parts
				.filter((pathPart) => !!pathPart)
				// Join path parts with slashes
				.join('/')
				// Remove leading and trailing slashes
				.replace(/^\/|\/$/g, '');

			let count = 1;
			let progress = isPageCompleted(pagePath) ? 1 : 0;

			// Recursively count pages and progress
			if (navigationItem.children) {
				navigationItem.children.forEach((page) => {
					const { count: childCount, progress: childProgress } =
						countPagesAndProgress(page);
					count += childCount;
					progress += childProgress;
				});
			}
			return {
				count,
				progress,
			};
		};

		const { count, progress } = countPagesAndProgress(
			chapterNavigationTree,
		);

		return Math.round((progress / count) * 100);
	}, [chapterId, chapterNavigationTree, chapterProgress]);

	const quizzesProgressPercentage = useMemo(() => {
		// Count total quizzes by going through all pages recursively and adding up the quizzes count
		const countQuizzes = (navigationItem: NavigationItem): Quiz[] => {
			const quizzes = navigationItem.interactives.quizzes ?? [];
			if (navigationItem.children) {
				navigationItem.children.forEach((page) => {
					quizzes.push(...countQuizzes(page));
				});
			}
			// Filter out double entries (path, id and type are the same)
			return quizzes.filter(
				(quiz, index, self) =>
					self.findIndex(
						(t) =>
							t.path === quiz.path &&
							t.id === quiz.id &&
							t.type === quiz.type,
					) === index,
			);
		};

		const totalQuizzes = countQuizzes(chapterNavigationTree);

		if (totalQuizzes.length === 0) {
			return 0;
		}

		// Count completed quizzes

		const completedQuizzes = totalQuizzes.filter((quiz) => {
			return (
				chapterProgress.quizzes?.find(
					(quizProgress) =>
						quizProgress.path === quiz.path &&
						quizProgress.id === quiz.id &&
						quizProgress.is_correct,
				) !== undefined
			);
		});

		return Math.round(
			(completedQuizzes.length / totalQuizzes.length) * 100,
		);
	}, [chapterNavigationTree, chapterProgress]);

	const isChapterCompleted = pagesProgressPercentage === 100;

	return {
		addPageToChapterProgress,
		removePageFromChapterProgress,
		isPageCompleted,
		pagesProgressPercentage,
		quizzesProgressPercentage,
		isChapterCompleted,
	};
};

export const useSingleQuizProgress = (path: string, quizId: string) => {
	const [chapterProgress, setChapterProgress] =
		useLocalStorage<ChapterProgress>(
			`chapterProgress-${path.split('/')[1]}`,
			defaultChapterProgress,
		);

	const quizProgress = (chapterProgress.quizzes ?? []).find(
		(quiz) => quiz.path === path && quiz.id === quizId,
	);

	const setQuizProgress = (
		selected_option_index: number,
		is_correct: boolean,
	) => {
		const quizzes = [
			...(chapterProgress.quizzes ?? []).filter(
				(quiz) => quiz.id !== quizId,
			),
			{
				path,
				id: quizId,
				selected_option_index,
				is_correct,
				added_at: new Date().toISOString(),
			},
		];
		setChapterProgress({ ...chapterProgress, quizzes });
	};

	return {
		quizProgress,
		setQuizProgress,
	};
};

export const useSingleChecklistProgress = (
	path: string,
	checklistId: string,
) => {
	const [chapterProgress, setChapterProgress] =
		useLocalStorage<ChapterProgress>(
			`chapterProgress-${path.split('/')[1]}`,
			defaultChapterProgress,
		);

	const checklistProgress = (chapterProgress.checklists ?? []).find(
		(checklist) => checklist.path === path && checklist.id === checklistId,
	);

	const setChecklistProgress = (selected_option_indexes: number[]) => {
		const checklists = [
			...(chapterProgress.checklists ?? []).filter(
				(checklist) => checklist.id !== checklistId,
			),
			{
				path,
				id: checklistId,
				selected_option_indexes,
				added_at: new Date().toISOString(),
			},
		];
		setChapterProgress({ ...chapterProgress, checklists });
	};

	return {
		checklistProgress,
		setChecklistProgress,
	};
};
