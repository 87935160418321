'use client';

import IconButton from '@/components/Button/IconButton';
import { mdiEye, mdiOpenInNew } from '@mdi/js';

const Iframe = ({ src, title }: { src: string; title: string }) => {
	const iFrameId = src.toLowerCase().replace(/[^a-zA-Z]+/g, '');
	const overlayId = iFrameId + '-overlay';

	return (
		<div className="not-prose relative mb-4 rounded-md bg-primary-200 shadow-sm dark:bg-slate-800">
			<iframe
				id={iFrameId}
				title={title}
				className="hidden aspect-video w-full rounded-md"
			/>
			<div
				id={overlayId}
				className="flex flex-col justify-between gap-4 p-4 text-center"
			>
				<strong>{title}</strong>
				<p className="break-words rounded-md bg-white p-4 text-lg">
					Hier kun je externe content bekijken, waardoor er mogelijk
					cookies van derden worden geplaatst. Link:{' '}
					<a
						href={src}
						target="_blank"
						rel="noopener noreferrer"
						className="break-all underline"
					>
						{src}
					</a>
				</p>
				<div className="flex flex-wrap items-center justify-center gap-2 rounded-md bg-white p-2">
					<IconButton
						label="Hier bekijken"
						icon={mdiEye}
						title="Externe content hier laden"
						onClick={() => {
							const iframe = document.getElementById(
								iFrameId,
							) as HTMLIFrameElement;
							iframe.src = src;
							iframe.classList.remove('hidden');
							iframe.focus();
							const overlay = document.getElementById(overlayId);
							overlay?.remove();
						}}
					/>
					<IconButton
						label="Open extern"
						icon={mdiOpenInNew}
						title="Externe content laden in nieuw tabblad"
						onClick={() => {
							window.open(src, '_blank');
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default Iframe;
