'use client';

import Twemoji from 'react-twemoji';

export const EmojiText = ({
	children,
}: {
	children?: Twemoji['props']['children'] | JSX.Element | string;
}) => (
	<Twemoji
		options={{
			base: '/assets/',
			folder: 'emoji',
			size: 'svg',
			ext: '.svg',
		}}
		tag="span"
	>
		{children}
	</Twemoji>
);

export default EmojiText;
