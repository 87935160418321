/* eslint-disable @next/next/no-img-element */
'use client';

import { Popover, Transition } from '@headlessui/react';
import { mdiCloseCircle } from '@mdi/js';
import { useLayoutEffect, useState } from 'react';
import IconButton from '../Button/IconButton';
import Section from '../Section/Section';

export interface ImgProps {
	src: string;
	alt: string;
	title?: string;
}

const Image = ({ src, alt, title }: ImgProps) => {
	const graphicComponent = title?.length ? (
		<figure className="pb-2">
			<img
				src={src}
				alt={alt}
				className="mx-auto rounded-lg bg-white/60 md:rounded-2xl"
			/>
			<figcaption className="text-balance px-2 pb-3 text-center text-sm font-semibold text-primary-800 dark:text-primary-200">
				{title}
			</figcaption>
		</figure>
	) : (
		<img src={src} alt={alt} className="rounded-lg md:rounded-2xl" />
	);

	return (
		<Section className="w-fit rounded-lg bg-primary-50 after:rounded-lg dark:bg-primary-950">
			{typeof window === 'undefined' ? null : <AltButton alt={alt} />}
			{graphicComponent}
		</Section>
	);
};

export default Image;

const AltButton = ({ alt }: { alt: string }) => {
	const [hasRendered, setHasRendered] = useState(false);

	useLayoutEffect(() => {
		setHasRendered(true);
	}, []);

	if (!hasRendered) {
		return null;
	}

	return (
		<Popover className="relative z-40">
			<Popover.Button
				className="absolute right-4 top-4 rounded-md bg-black/60 text-sm font-bold text-white backdrop-blur-md transition-all duration-500 active:opacity-75 hover:opacity-90 md:text-base"
				title="Alternatieve tekst"
				aria-label="Alternatieve tekst van afbeelding openen"
			>
				<div className="relative z-1 px-3 py-1.5">
					<div className="absolute inset-0 size-full">
						<div className="shadow-border-bottom-sm size-full after:rounded-md" />
					</div>
					<span aria-hidden>ALT</span>
					<span className="sr-only">Alternatieve tekst</span>
				</div>
			</Popover.Button>
			<Transition
				enter="transition duration-200 ease-out"
				enterFrom="motion-safe:scale-50 opacity-0"
				enterTo="motion-safe:scale-100 opacity-100"
				leave="transition duration-100 ease-out"
				leaveFrom="motion-safe:scale-100 opacity-100"
				leaveTo="motion-safe:scale-50 opacity-0"
				className="z-100 origin-top-right"
			>
				<Popover.Panel className="not-prose absolute top-4 z-100 w-full max-w-full rounded-xl bg-primary-50 p-4 text-primary-950 shadow-2xl ring-2 ring-black/5 ring-opacity-5 focus:outline-none md:right-4 md:w-[30rem] dark:bg-slate-900 dark:text-slate-50 dark:ring-primary/20">
					{({ close }) => (
						<>
							<header className="font-brand text-lg font-bold">
								Alternatieve tekst
							</header>
							<p className="text-base">{alt}</p>
							<IconButton
								icon={mdiCloseCircle}
								className="mt-4 w-full justify-center"
								title="Sluit alternatieve tekst"
								aria-label="Sluit alternatieve tekst van afbeelding"
								label="Sluit"
								colorVariant="primary"
								onClick={() => close()}
							/>
						</>
					)}
				</Popover.Panel>
			</Transition>
		</Popover>
	);
};
