'use client';

import { Prose } from '../ArticleAccessibilityMenu/ArticleAccessibilityTextSize';
import InteractiveSection from './InteractiveSection';

interface TipInfo {
	title: string;
	content: string;
}

const Tips: Record<string, TipInfo> = {
	projects_help: {
		title: 'Hulp nodig?',
		content:
			'NTR Digitaal kan helpen met het maken van stappen naar een toegankelijke productie. Of het nu gaat om een website, video of podcast: wij ondersteunen je graag.',
	},
	settings_simulations_color: {
		title: 'Simulaties',
		content:
			'Je kan op deze site bovenaan ieder artikel op de instellingen knop klikken op verschillende soorten kleurenblindheid op de site te simuleren.',
	},
} as const;

const SingleTip = ({ id }: { id: string }) => {
	const tip: TipInfo = Tips[id] ?? {
		title: 'Tip niet gevonden',
		content:
			'Deze tip is niet gevonden. Neem contact op met de beheerder van deze site.',
	};

	return (
		<InteractiveSection
			headingLevel="h2"
			title={tip.title}
			subtitle="Tip"
			emoji="💡"
			colorVariant="primary"
		>
			<div className="shadow-border-bottom-sm rounded-lg bg-white p-4 after:rounded-lg dark:bg-black">
				<Prose>{tip.content}</Prose>
			</div>
		</InteractiveSection>
	);
};

export default SingleTip;
