'use client';

import Section from '@/components/Section/Section';
import { Disclosure } from '@headlessui/react';
import { mdiArrowLeft, mdiChevronRight, mdiLightbulb } from '@mdi/js';
import Icon from '@mdi/react';
import { motion, useReducedMotion } from 'framer-motion';
import { ReactNode, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import IconButton from '../Button/IconButton';

type AltDecisionTreeBranch = {
	id: string;
	title: string;
	subtitle?: ReactNode;
	options: AltDecisionTreeOption[];
};

type AltDecisionTreeOption = {
	id: string;
	title: string;
	subtitle: string;
	instruction?: string;
	conditionalInstructions?: {
		condition: string;
		instruction: ReactNode;
	}[];
	subBranch?: AltDecisionTreeBranch;
};

const altDecisionTree: AltDecisionTreeBranch = {
	id: 'information-decoration',
	title: 'Informatie of decoratie?',
	options: [
		{
			id: 'information',
			title: 'Informatie',
			subtitle: 'Content zoals een infographic, foto of tekening',
			subBranch: {
				id: 'complex',
				title: 'Gaat het om complexe informatie?',
				subtitle:
					'Denk aan een grafiek, diagram of een afbeelding met veel details die allemaal belangrijk zijn.',
				options: [
					{
						id: 'complex-no',
						title: 'Niet complex',
						subtitle:
							'De afbeelding bevat niet te veel informatie en is makkelijk te beschrijven',
						subBranch: {
							id: 'text',
							title: 'Bevat de afbeelding tekst?',
							subtitle:
								'Vermeid het gebruik van tekst in afbeeldingen. Als het niet anders kan, zorg dan dat de tekst ook in de alt-tekst staat.',
							options: [
								{
									id: 'text-no',
									title: 'Geen tekst',
									subtitle: 'De afbeelding bevat geen tekst',
									subBranch: {
										id: 'link',
										title: 'Is de afbeelding een link?',
										subtitle:
											'Is de afbeelding een link en op geen andere manier, zoals een tekstueel label, te begrijpen?',
										options: [
											{
												id: 'no',
												title: 'Geen link',
												subtitle:
													'De afbeelding is geen link',
												instruction:
													'Begin je alternatieve tekst met het type afbeelding, bijvoorbeeld: "Foto van..." of "Illustratie van...". Daarna begin je met de meest relevante informatie. Zo hoor je het belangrijkste eerst. Lees hieronder meer informatie.',
											},
											{
												id: 'link-yes-has-text',
												title: 'Link, maar al tekstueel duidelijk',
												subtitle:
													'Het doel is wel duidelijk vanuit een tekstueel label',
												instruction:
													'Gebruik een lege alt text als de link al een duidelijk (in code gekoppeld) label heeft met echte tekst, tenzij de afbeelding extra informatie geeft over de link.',
											},
											{
												id: 'link-yes-no-text',
												title: 'Link en nog niet duidelijk',
												subtitle:
													'Het doel is niet duidelijk vanuit een tekstueel label',
												instruction:
													'Beschrijf het doel van de link in de alt-tekst.',
											},
										],
									},
								},
								{
									id: 'text-yes',
									title: 'Er is tekst',
									subtitle: 'De afbeelding bevat tekst',
									instruction:
										'Probeer deze afbeelding niet zomaar te gebruiken, maar wil je dit wel? Gebruik dan de volgende richtlijnen:',
									conditionalInstructions: [
										{
											condition:
												'De tekst is identiek aan echte tekst die dichtbij staat',
											instruction:
												'Gebruik een lege alternative tekst, zodat de tekst niet dubbel wordt voorgelezen.',
										},
										{
											condition:
												'De tekst heeft een functie, bijvoorbeeld een icoon',
											instruction:
												'Gebruik de alternatieve tekst om de functie van de tekst te beschrijven. Bijvoorbeeld: het icoon om tekst bold te maken is een letter B, maar die informatie is niet relevant. De functie ervan is wel relevant.',
										},
										{
											condition:
												'De tekst staat verder nergens',
											instruction:
												'Vermeid dit altijd, maar als het niet anders kan, zorg dan dat die tekst ook letterlijk in de alt-tekst staat.',
										},
									],
								},
							],
						},
					},
					{
						id: 'complex-yes',
						title: 'Wel complex',
						subtitle:
							'De afbeelding bevat veel informatie en is moeilijk kort gelijkwaardig te beschrijven',
						instruction:
							'Bied de content ook tekstueel aan, bijvoorbeeld in een tekst, tabel of lijst ernaast. De toegankelijke, tekstuele, versie moet gelijkwaardig zijn. Je kan voor de afbeelding een lege alt-tekst gebruiken.',
					},
				],
			},
		},
		{
			id: 'decoration',
			title: 'Decoratie',
			subtitle: 'Geen content, zoals een achtergrondpatroon',
			instruction:
				'Als de afbeelding geen betekenis heeft gebruik je een lege alternative tekst, zodat de afbeelding niet wordt voorgelezen.',
		},
	],
};

const findBranchOrOptionByBranchID = (
	id: string,
	branch: AltDecisionTreeBranch,
): AltDecisionTreeBranch | AltDecisionTreeOption | undefined => {
	if (branch.id === id) {
		return branch;
	}
	for (const option of branch.options) {
		if (option.id === id) {
			return option;
		}
		if (option.subBranch) {
			const result = findBranchOrOptionByBranchID(id, option.subBranch);
			if (result) {
				return result;
			}
		}
	}
	return undefined;
};

const findOptionThatLedToBranchByBranchId = (
	id: string,
): AltDecisionTreeOption | undefined => {
	const findOption = (
		branch: AltDecisionTreeBranch,
	): AltDecisionTreeOption | undefined => {
		for (const option of branch.options) {
			if (option.subBranch?.id === id) {
				return option;
			}
			if (option.subBranch) {
				const foundOption = findOption(option.subBranch);
				if (foundOption) {
					return foundOption;
				}
			}
		}
		return undefined;
	};

	return findOption(altDecisionTree);
};

const AltDecisionTree = () => {
	const reduceMotion = useReducedMotion();

	const [chosenOptionsPath, setChosenOptionsPath] = useState<string[]>([
		'information-decoration',
	]);
	const [lastAction, setLastAction] = useState<'push' | 'pop'>('push');

	return (
		<Section
			className="not-prose overflow-hidden bg-primary-600 text-lg"
			colorVariant="primary"
		>
			<hgroup className="bg-primary-700 p-4">
				<h2 className="text-3xl">
					Wat voor alternative tekst heb je nodig?
				</h2>
				<p>
					Bekijk de opties en kom erachter! Hieronder lees je ook meer
					informatie.
				</p>
			</hgroup>
			<div className="py-4">
				{chosenOptionsPath.length > 1 && (
					<div className="flex items-start gap-2 p-4 pt-0 max-sm:flex-col sm:flex-wrap sm:items-center">
						<IconButton
							icon={mdiArrowLeft}
							label="Terug"
							title="Stap terug"
							onClick={() => {
								setChosenOptionsPath(
									chosenOptionsPath.slice(0, -1),
								);
								setLastAction('pop');
							}}
						/>
						{chosenOptionsPath
							.map((branchId) => ({
								branchId,
								title: findOptionThatLedToBranchByBranchId(
									branchId,
								)?.title,
							}))
							.filter((branch) => branch.title)
							.map((branch) => (
								<button
									key={branch.branchId}
									type="button"
									className="rounded-full bg-primary-800 px-4 py-2 font-bold"
									onClick={() => {
										setChosenOptionsPath(
											chosenOptionsPath.slice(
												0,
												chosenOptionsPath.indexOf(
													branch.branchId,
												) + 1,
											),
										);
										setLastAction('pop');
									}}
								>
									{branch.title}
								</button>
							))}
					</div>
				)}

				<motion.div
					key={chosenOptionsPath.join()}
					initial={{
						opacity: 0,
						x: reduceMotion
							? 0
							: lastAction === 'push'
								? '5%'
								: '-5%',
					}}
					animate={{
						opacity: 1,
						x: 0,
						transition: {
							delay: 0.25,
							ease: 'circOut',
							duration: 0.3,
						},
					}}
				>
					<AltDecisionTreeBranch
						branch={
							findBranchOrOptionByBranchID(
								chosenOptionsPath[chosenOptionsPath.length - 1],
								altDecisionTree,
							) as AltDecisionTreeBranch
						}
						onBranchedDeeper={(branchId) => {
							setChosenOptionsPath([
								...chosenOptionsPath,
								branchId,
							]);
							setLastAction('push');
						}}
					/>
				</motion.div>
			</div>
		</Section>
	);
};

const AltDecisionTreeBranch = ({
	branch,
	onBranchedDeeper,
}: {
	branch: AltDecisionTreeBranch;
	onBranchedDeeper: (id: string) => void;
}) => {
	return (
		<section aria-label={branch.title}>
			<hgroup className="px-4">
				<p className="text-2xl font-bold">{branch.title}</p>
				<p>{branch.subtitle}</p>
			</hgroup>
			<ul className="grid gap-4 pt-4">
				{branch.options.map((option) => (
					<li key={option.id} className="md:px-4">
						<Section>
							{option.subBranch ? (
								<button
									type="button"
									className={twMerge(
										'flex w-full p-4 text-start',
									)}
									onClick={() => {
										if (option?.subBranch)
											onBranchedDeeper(
												option?.subBranch.id,
											);
									}}
								>
									<div className="grid grow">
										<span className="text-xl font-bold">
											{option.title}
										</span>
										<span>{option.subtitle}</span>
									</div>
									<Icon
										path={mdiChevronRight}
										className="shrink-0"
										size={1}
									/>
								</button>
							) : (
								<Disclosure
									as="section"
									aria-label={option.title}
								>
									{({ open }) => (
										<>
											<Disclosure.Button
												className={twMerge(
													'flex w-full p-4 text-start md:rounded-t-2xl',
													open &&
														'bg-primary-700 text-white',
												)}
											>
												<div className="grid grow">
													<span className="text-xl font-bold">
														{option.title}
													</span>
													<span>
														{option.subtitle}
													</span>
												</div>
												<Icon
													path={mdiChevronRight}
													className={twMerge(
														'shrink-0 transition-all',
														open && '-rotate-90',
													)}
													size={1}
												/>
											</Disclosure.Button>
											<Disclosure.Panel
												className={twMerge(
													'grid gap-2 py-4 md:rounded-b-2xl',
													option?.instruction ||
														option?.conditionalInstructions
														? 'bg-primary-800 text-white'
														: 'bg-primary-100 text-primary-800',
												)}
											>
												{option?.instruction && (
													<p className="px-4 font-semibold">
														<Icon
															path={mdiLightbulb}
															size={0.75}
															className="me-1 inline-block -translate-y-0.5"
														/>
														{option.instruction}
													</p>
												)}
												{option?.conditionalInstructions && (
													<ul className="grid gap-2 px-4">
														{option.conditionalInstructions.map(
															(instruction) => (
																<li
																	key={
																		instruction.condition
																	}
																>
																	<p className="text-lg font-black">
																		{
																			instruction.condition
																		}
																	</p>
																	<p className="font-semibold">
																		{
																			instruction.instruction
																		}
																	</p>
																</li>
															),
														)}
													</ul>
												)}
											</Disclosure.Panel>
										</>
									)}
								</Disclosure>
							)}
						</Section>
					</li>
				))}
			</ul>
		</section>
	);
};

export default AltDecisionTree;
