import { StaticImageData } from 'next/image';
import Link from 'next/link';
import { ReactNode } from 'react';

type DisabilityCategory = 'visueel' | 'auditief' | 'motorisch' | 'neurologisch';
type DisabilityDuration = 'permanent' | 'tijdelijk' | 'situationeel';
type DisabilityValue =
	| 'blind'
	| 'slechtziend'
	| 'kleurenblind'
	| 'doof'
	| 'slechthorend'
	| 'tinnitus'
	| 'rolstoel'
	| 'scootmobiel'
	| 'autisme'
	| 'adhd'
	| 'dyslexie'
	| 'dyscalculie'
	| 'dyspraxie'
	| 'hersenschudding'
	| 'epilepsie'
	| 'ms'
	| 'parkinson'
	| 'reuma'
	| 'spierziekte';

type DisabilityCategoryAndValueSet = {
	category: DisabilityCategory;
	value: DisabilityValue;
	duration: DisabilityDuration;
	description: string;
};

type DisabilityCategoryAndValue = DisabilityCategoryAndValueSet &
	(
		| {
				category: 'visueel';
				value: 'blind';
		  }
		| {
				category: 'visueel';
				value: 'slechtziend';
		  }
		| {
				category: 'visueel';
				value: 'kleurenblind';
		  }
		| {
				category: 'auditief';
				value: 'doof';
		  }
		| {
				category: 'auditief';
				value: 'slechthorend';
		  }
		| {
				category: 'auditief';
				value: 'tinnitus';
		  }
		| {
				category: 'motorisch';
				value: 'parkinson';
		  }
		| {
				category: 'motorisch';
				value: 'reuma';
		  }
		| {
				category: 'motorisch';
				value: 'spierziekte';
		  }
		| {
				category: 'neurologisch';
				value: 'autisme';
		  }
		| {
				category: 'neurologisch';
				value: 'adhd';
		  }
		| {
				category: 'neurologisch';
				value: 'dyslexie';
		  }
		| {
				category: 'neurologisch';
				value: 'dyscalculie';
		  }
		| {
				category: 'neurologisch';
				value: 'dyspraxie';
		  }
		| {
				category: 'neurologisch';
				value: 'hersenschudding';
		  }
		| {
				category: 'neurologisch';
				value: 'epilepsie';
		  }
		| {
				category: 'neurologisch';
				value: 'ms';
		  }
	);

export const DetailLabel: Record<
	string,
	{
		displayName: string;
		emoji: string;
	}
> = {
	hobby: {
		displayName: 'Hobby',
		emoji: '🎨',
	},
	morningRitual: {
		displayName: 'Ochtendritueel',
		emoji: '🌅',
	},
	study: {
		displayName: 'Studie',
		emoji: '📚',
	},
} as const;

export const TestLabel: Record<
	string,
	{
		displayName: ReactNode;
	}
> = {
	contrastChecker: {
		displayName: (
			<>
				Gebruik onze <Link href="/contrast">contrast checker</Link>.
			</>
		),
	},
	colorIndependence: {
		displayName: (
			<>
				Is informatie{' '}
				<Link href="/ux-ontwerper/design-spec/kleur">
					kleur-onafhankelijk
				</Link>
				? Vergeet niet om ook grafieken, kaarten en andere graphics ook
				te controleren.
			</>
		),
	},
	focusOutline: {
		displayName: (
			<>
				Focusomlijning: is deze altijd zichtbaar en heeft deze genoeg{' '}
				<Link href="/contrast">contrast</Link>?
			</>
		),
	},
	keyboardNavigation: {
		displayName: 'Is alles volledig te bedienen via het toetsenbord?',
	},
	textParsable: {
		displayName:
			'Is alle tekst goed te lezen door toegankelijkheidstools? Kan het bijvoorbeeld worden voorgelezen door het systeem?',
	},
	ownPace: {
		displayName: 'Kan de gebruiker alles in eigen tempo doen?',
	},
	noSound: {
		displayName:
			'Zet je geluid uit. Is alles te begrijpen zonder geluid? Krijg je dezelfde informatie binnen? Is het gelijkwaardig?',
	},
	captions: {
		displayName: (
			<>
				Is er{' '}
				<Link href="/redacteur/audio-video/ondertiteling">
					ondertiteling
				</Link>{' '}
				beschikbaar volgens de NPO richtlijnen?
			</>
		),
	},
	noAnimations: {
		displayName:
			'Wanneer in de systeeminstellingen animaties uitgezet zijn, respecteert de website dit? Hebben alle animaties die langer dan 5 seconden zijn een pauze/stop knop?',
	},
} as const;

export const ToolLabel: Record<
	string,
	{
		displayName: ReactNode;
	}
> = {
	colorFilter: {
		displayName:
			'Kleurfilter dat het contrast verhoogt voor mensen met kleurenblindheid',
	},
	screenReaderKeyboard: {
		displayName: 'Schermlezer met toetsenbordnavigatie',
	},
	screenReaderMobileTouch: {
		displayName: 'Mobiele schermlezer met touch-navigatie',
	},
	screenReaderMobileSwipe: {
		displayName: 'Mobiele schermlezer met swipe-navigatie',
	},
	screenReaderMobileVoice: {
		displayName: 'Mobiele schermlezer met stem-navigatie',
	},
	magnifier: {
		displayName: 'Vergrootglas van het systeem',
	},
	wheelchair: {
		displayName: 'Rolstoel',
	},
	textToSpeech: {
		displayName: 'Tekst-naar-spraak',
	},
	spokenSubtitles: {
		displayName: 'Gesproken ondertiteling (voor vreemde talen)',
	},
	captions: {
		displayName: 'Ondertiteling',
	},
	signLanguage: {
		displayName: 'Gebarentaal',
	},
	noAnimations: {
		displayName: 'Systeeminstelling om animaties uit te zetten',
	},
} as const;

export type Persona = {
	id: string;
	name: string;
	age: number;
	image: {
		src: StaticImageData;
		alt: string;
	};
	biography: string;
	disabilities: DisabilityCategoryAndValue[];
	details: {
		id: keyof typeof DetailLabel;
		value: ReactNode;
	}[];
	test: (keyof typeof TestLabel)[];
	tools: (keyof typeof ToolLabel)[];
};

export const getPersonaSlug = (persona: Persona) =>
	`${persona.name.toLocaleLowerCase()}-${persona.age}-${persona.disabilities
		.map((d) => d.value)
		.join('-')}`;
