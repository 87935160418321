'use client';

import PersonaCard from '@/components/Persona/PersonaCard';
import Personas from '@/resources/personas/personas';
import EmojiButton from '../Button/EmojiButton';
import InteractiveSection from './InteractiveSection';

const PersonasCard = ({
	disabilityCategory,
}: {
	disabilityCategory: string;
}) => {
	const filteredPersonas = Personas.filter((persona) =>
		persona.disabilities.some((disability) =>
			disabilityCategory
				? disability.category === disabilityCategory
				: true,
		),
	).slice(0, 4);

	if (filteredPersonas.length === 0) return null;

	return (
		<InteractiveSection
			headingLevel="h2"
			title="Persona's"
			subtitle="In het leven van"
			emoji="🗂️"
			colorVariant="tertiary"
		>
			<ul className="grid gap-2 lg:grid-cols-2">
				{filteredPersonas.map((persona) => (
					<PersonaCard
						key={persona.id}
						persona={persona}
						headingLevel="h3"
					/>
				))}
			</ul>
			<EmojiButton
				url="/personas"
				className="mx-auto mt-4 w-fit"
				colorVariant="tertiary"
				label="Alle persona's"
			/>
		</InteractiveSection>
	);
};

export default PersonasCard;
