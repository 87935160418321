import { twMerge } from 'tailwind-merge';
import styles from './Section.module.sass';

const colorVariants: Record<
	string,
	{
		background: string;
	}
> = {
	default: {
		background: 'bg-white dark:bg-slate-900 text-slate-800 dark:text-white',
	},
	primary: {
		background: 'bg-primary-500 text-white dark:bg-primary-700',
	},
	secondary: {
		background: 'bg-secondary-500 text-white dark:bg-secondary-700',
	},
	tertiary: {
		background: 'bg-tertiary-500 text-white dark:bg-tertiary-700',
	},
	quaternary: {
		background: 'bg-quaternary-500 text-white dark:bg-quaternary-800',
	},
	quinary: {
		background: 'bg-quinary-500 text-white dark:bg-quinary-700',
	},
} as const;

type ColorVariant = keyof typeof colorVariants;

export const SectionColorVariants = colorVariants;

const Section = ({
	as = 'section',
	colorVariant = 'default',
	className,
	children,
	...props
}: {
	as?: 'section' | 'aside' | 'article' | 'div' | 'main';
	colorVariant?: ColorVariant;
	className?: string;
	children?: React.ReactNode;
} & React.HTMLProps<HTMLDivElement>) => {
	const Component = as;

	return (
		<Component
			className={twMerge(
				styles.section,
				'shadow-border-bottom-md shadow-lg after:z-20 md:rounded-2xl md:after:rounded-2xl',
				colorVariants[colorVariant].background,
				className,
			)}
			{...props}
		>
			{children}
		</Component>
	);
};

export default Section;
