import Link from 'next/link';
import { twMerge } from 'tailwind-merge';
import EmojiText from '../EmojiText/EmojiText';

const colorVariants: Record<string, string> = {
	primary:
		'bg-white dark:bg-primary-900 text-primary-700 dark:text-primary-50 hover:bg-primary-200 dark:hover:bg-primary-800',
	secondary:
		'bg-white dark:bg-secondary-900 text-secondary-700 dark:text-secondary-50 hover:bg-secondary-200 dark:hover:bg-secondary-800',
	tertiary:
		'bg-white dark:bg-tertiary-900 text-tertiary-700 dark:text-tertiary-50 hover:bg-tertiary-200 dark:hover:bg-tertiary-800',
	quaternary:
		'bg-white dark:bg-quaternary-900 text-quaternary-700 dark:text-quaternary-50 hover:bg-quaternary-200 dark:hover:bg-quaternary-800',
	quinary:
		'bg-white dark:bg-quinary-900 text-quinary-700 dark:text-quinary-50 hover:bg-quinary-200 dark:hover:bg-quinary-800',
} as const;

type ColorVariant = keyof typeof colorVariants;

const EmojiButton = ({
	emoji,
	label,
	onClick,
	url,
	colorVariant = 'primary',
	className,
	type,
}: {
	emoji?: string;
	label: string;
	onClick?: () => void;
	url?: string;
	colorVariant?: ColorVariant;
	className?: string;
	type?: 'button' | 'submit' | 'reset';
}) => {
	const styles = twMerge(
		'shadow-border-bottom-sm hover:opacity:80 group flex items-center gap-1 text-start rounded-full py-1 px-4 text-lg font-bold shadow-md transition-all after:rounded-full active:opacity-95',
		colorVariants[colorVariant],
		url
			? 'no-underline hover:underline hover:underline-offset-3 hover:decoration-2'
			: '',
		className,
	);

	const content = (
		<>
			{!!emoji && (
				<div
					aria-hidden
					className="emoji-illustration-container -ms-2 flex size-8 shrink-0 select-none items-center justify-center rounded-full pb-0.5 transition-all motion-safe:group-active:scale-100 motion-safe:group-hover:-rotate-6 motion-safe:group-hover:scale-125"
				>
					<EmojiText>{emoji}</EmojiText>
				</div>
			)}
			<span className="line-clamp-1 grow">{label}</span>
		</>
	);

	if (url)
		return (
			<Link href={url} className={styles} prefetch={false}>
				{content}
			</Link>
		);
	else
		return (
			<button onClick={onClick} className={styles} type={type}>
				{content}
			</button>
		);
};

export default EmojiButton;
