import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import EmojiText from '../EmojiText/EmojiText';
import Section from '../Section/Section';

const colorVariants: Record<
	string,
	{
		background: string;
		foreground: string;
	}
> = {
	primary: {
		background: 'bg-primary-100 dark:bg-primary-950',
		foreground: 'text-primary-900 dark:text-white',
	},
	secondary: {
		background: 'bg-secondary-100 dark:bg-secondary-950',
		foreground: 'text-secondary-900 dark:text-white',
	},
	tertiary: {
		background: 'bg-tertiary-100 dark:bg-tertiary-900',
		foreground: 'text-tertiary-900 dark:text-white',
	},
	quaternary: {
		background: 'bg-quaternary-100 dark:bg-quaternary-950',
		foreground: 'text-quaternary-900 dark:text-white',
	},
	quinary: {
		background: 'bg-quinary-100 dark:bg-quinary-950',
		foreground: 'text-quinary-900 dark:text-white',
	},
	senary: {
		background: 'bg-senary-100 dark:bg-senary-950',
		foreground: 'text-senary-900 dark:text-white',
	},
} as const;

type ColorVariant = keyof typeof colorVariants;

const InteractiveSection = ({
	headingLevel,
	title,
	subtitle,
	emoji,
	colorVariant = 'primary',
	className,
	children,
}: {
	headingLevel: 'h2' | 'h3' | 'h4';
	title: string;
	subtitle: string;
	emoji: ReactNode;
	colorVariant?: ColorVariant;
	className?: {
		background: string;
		foreground: string;
	};
	children: ReactNode;
}) => {
	const HeadingComp = headingLevel;

	return (
		<Section
			aria-label={title}
			className={twMerge(
				'not-prose mb-4 rounded-lg p-4 shadow-md after:rounded-lg md:p-6 md:pt-4',
				colorVariants[colorVariant].background,
				className?.background,
			)}
		>
			<div
				className={twMerge(
					'-mb-1 text-base font-bold',
					colorVariants[colorVariant].foreground,
					className?.foreground,
				)}
			>
				{subtitle}
			</div>
			<HeadingComp
				className={twMerge(
					'flex justify-between gap-1 pb-3 text-2xl font-bold',
					colorVariants[colorVariant].foreground,
					className?.foreground,
				)}
			>
				<span>{title}</span>
				<div
					aria-hidden
					className="ms-2 shrink-0 -translate-y-2.5 rotate-6 scale-150"
				>
					{typeof emoji === 'string' ? (
						<EmojiText>{emoji}</EmojiText>
					) : (
						emoji
					)}
				</div>
			</HeadingComp>
			{children}
		</Section>
	);
};

export default InteractiveSection;
