'use client';

import EmojiButton from '@/components/Button/EmojiButton';
import IconButton from '@/components/Button/IconButton';
import EmojiText from '@/components/EmojiText/EmojiText';
import {
	QuizProgress,
	useSingleQuizProgress,
} from '@/hooks/useChapterProgress';
import { Disclosure, Transition } from '@headlessui/react';
import {
	mdiCheck,
	mdiCheckDecagram,
	mdiChevronUp,
	mdiCloseCircle,
	mdiRadioboxBlank,
	mdiRadioboxMarked,
} from '@mdi/js';
import Icon from '@mdi/react';
import { motion, useReducedMotion } from 'framer-motion';
import { usePathname } from 'next/navigation';
import { useCallback, useState } from 'react';
import InteractiveSection from './InteractiveSection';

interface MultipleChoiceOption {
	text: string;
	isCorrect: boolean;
	explanation: string;
}

interface DisclosurePanel {
	close: () => void;
	key: number;
}

const MultipleChoice = ({
	id,
	question,
	options,
}: {
	id: string;
	question: string;
	options: string;
}) => {
	const reduceMotion = useReducedMotion();
	const pathname = usePathname() ?? '';
	const firstPathnameSegment = pathname.split('/')[1];
	const guidelinesLink = `/${firstPathnameSegment}/richtlijnen`;

	const { quizProgress, setQuizProgress } = useSingleQuizProgress(
		pathname,
		id,
	);

	const [activeDisclosure, setActiveDisclosure] =
		useState<DisclosurePanel | null>(null);

	const setOpenDisclosure = useCallback(
		(panel: DisclosurePanel) => {
			try {
				activeDisclosure?.close();
			} catch (e) {
				// Ignore
			}

			setActiveDisclosure(panel);
		},
		[activeDisclosure, activeDisclosure?.close],
	);

	const parsedOptions = JSON.parse(options) as MultipleChoiceOption[];

	const isCorrect =
		quizProgress?.selected_option_index !== undefined &&
		!!parsedOptions[quizProgress.selected_option_index]?.isCorrect;

	return (
		<InteractiveSection
			headingLevel="h2"
			title={question}
			subtitle="Quiz"
			emoji={
				reduceMotion ? (
					<EmojiText>{isCorrect ? '🎉' : '🤔'}</EmojiText>
				) : (
					<>
						<motion.span
							animate={{
								rotate: isCorrect ? 40 : 6,
								scale: isCorrect ? 0 : 1,
							}}
						>
							<EmojiText>🤔</EmojiText>
						</motion.span>
						<motion.span
							className="absolute"
							animate={{
								rotate: !isCorrect ? 40 : [0, 12, 6],
								scale: !isCorrect ? 0 : [0, 1.25, 1],
							}}
						>
							<EmojiText>🎉</EmojiText>
						</motion.span>
					</>
				)
			}
			colorVariant="quaternary"
		>
			{question.includes('richtlijn') && (
				<EmojiButton
					url={guidelinesLink}
					className="mb-4 w-fit"
					colorVariant="quaternary"
					emoji="📖"
					label="Bekijk mijn richtlijnen"
				/>
			)}
			<ul aria-label="Antwoorden" className="grid gap-4">
				{parsedOptions.map((option, index) => (
					<AnswerOption
						key={index}
						optionIndex={index}
						option={option}
						setOpenDisclosure={setOpenDisclosure}
						setDisclosureToClosed={() => setActiveDisclosure(null)}
						isCorrect={isCorrect && option.isCorrect}
						quizProgress={quizProgress}
						setQuizProgress={setQuizProgress}
					/>
				))}
			</ul>
		</InteractiveSection>
	);
};

const AnswerOption = ({
	optionIndex,
	option,
	setOpenDisclosure,
	setDisclosureToClosed,
	isCorrect,
	quizProgress,
	setQuizProgress,
}: {
	optionIndex: number;
	option: MultipleChoiceOption;
	setOpenDisclosure: (panel: DisclosurePanel) => void;
	setDisclosureToClosed: () => void;
	isCorrect: boolean;
	quizProgress?: QuizProgress;
	setQuizProgress: (index: number, isCorrect: boolean) => void;
}) => {
	const selectAnswerOption = () => {
		setQuizProgress(optionIndex, option.isCorrect);
		setOpenDisclosure({
			close,
			key: optionIndex,
		});
	};

	return (
		<Disclosure>
			{({ open, close }) => (
				<li
					className={`shadow-border-bottom-sm grid rounded-3xl bg-white after:rounded-3xl dark:bg-black/30 ${
						open &&
						quizProgress?.selected_option_index === optionIndex
							? 'shadow-xl'
							: 'shadow-sm active:shadow-sm hover:shadow-lg'
					} ${
						!open
							? 'hover:bg-quaternary-100 dark:hover:bg-quaternary-800'
							: ''
					} overflow-hidden transition-all duration-300`}
				>
					<Disclosure.Button
						className={`m-1 flex items-center justify-between gap-2 rounded-2.5xl p-4 pe-5 text-start font-semibold text-quaternary-950 md:text-xl dark:text-white ${
							!open ? '' : 'cursor-default'
						} transition-colors`}
						onClick={() => {
							if (!open) {
								selectAnswerOption();
							} else {
								close();
								setDisclosureToClosed();
							}
						}}
					>
						<Icon
							aria-hidden
							className="me-2 shrink-0 max-md:hidden"
							path={
								quizProgress?.selected_option_index ===
								optionIndex
									? mdiRadioboxMarked
									: mdiRadioboxBlank
							}
							size={1.1}
						/>
						<span className="grow text-balance">{option.text}</span>
						{quizProgress?.selected_option_index ===
							optionIndex && (
							<div
								className={`flex shrink-0 items-center gap-2 ${
									isCorrect
										? 'text-green-800 dark:text-green-300'
										: 'text-red-800 dark:text-pink-300'
								}`}
							>
								<span className="max-md:hidden">
									Jouw keuze
								</span>
								<span className="sr-only">
									{isCorrect
										? ' is correct'
										: 'is helaas niet correct'}
								</span>
								<Icon
									path={
										isCorrect
											? mdiCheckDecagram
											: mdiCloseCircle
									}
									size={1.1}
								/>
							</div>
						)}
					</Disclosure.Button>
					<Transition
						enter="transition duration-150 ease-out"
						enterFrom="motion-safe:scale-95 opacity-0"
						enterTo="motion-safe:scale-100 opacity-100"
						leave="transition duration-0 ease-out"
						leaveFrom="motion-safe:scale-100 opacity-100"
						leaveTo="motion-safe:scale-95 opacity-0"
					>
						<Disclosure.Panel>
							{({ close }) => {
								return (
									<div className="border-t-2 border-t-quaternary-200 px-6 py-4 dark:border-t-quaternary-800">
										<p>
											<EmojiText>
												{isCorrect
													? 'Juist! 🥳'
													: 'Volgende keer beter!'}
												{'  '}
												{option.explanation}
											</EmojiText>
										</p>
										<div className="flex flex-wrap gap-2">
											<IconButton
												label="Uitleg verbergen"
												title="Uitleg verbergen"
												className="mb-2 mt-4"
												colorVariant="quaternary"
												onClick={() => {
													close();
													setDisclosureToClosed();
												}}
												icon={mdiChevronUp}
											/>
											{!isCorrect &&
												quizProgress?.selected_option_index !==
													optionIndex && (
													<IconButton
														label="Dit antwoord selecteren"
														title="Antwoord selecteren"
														className="mb-2 mt-4"
														colorVariant="quaternary"
														onClick={() => {
															selectAnswerOption();
														}}
														icon={mdiCheck}
													/>
												)}
										</div>
									</div>
								);
							}}
						</Disclosure.Panel>
					</Transition>
				</li>
			)}
		</Disclosure>
	);
};

export default MultipleChoice;
