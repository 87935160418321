import { Persona } from './persona';

import ImageAlex from './images/alex.jpg';
import ImageJade from './images/jade.jpg';
import ImageLola from './images/lola.jpg';
import ImageNoah from './images/noah.jpg';
import ImageRico from './images/rico.jpg';
import ImageTina from './images/tina.jpg';
import ImageVienna from './images/vienna.jpg';

const Personas: Persona[] = [
	{
		id: 'lola',
		name: 'Lola',
		age: 34,
		image: {
			src: ImageLola,
			alt: 'Lachend persoon met lange bruine haren en shirt met een wild patroon',
		},
		biography:
			"Lola houdt van gamen, televisie en radio. Ze kijkt iedere ochtend naar het nieuws en luistert graag naar muziek. Op haar zesentwintigste was er pas ontdekt dat ze kleurenblind is. Doordat veel televisieprogramma's geen rekening houden met haar kleurenblindheid, kan ze niet altijd alle informatie lezen of grafieken begrijpen.",
		disabilities: [
			{
				category: 'visueel',
				value: 'kleurenblind',
				duration: 'permanent',
				description: 'tritanopie (blauw-geel kleurenblindheid)',
			},
		],
		details: [
			{
				id: 'hobby',
				value: "gamen, muziekprogramma's op televisie en radio",
			},
			{ id: 'morningRitual', value: 'het nieuws kijken' },
		],
		test: ['contrastChecker', 'colorIndependence'],
		tools: ['colorFilter'],
	},
	{
		id: 'alex',
		name: 'Alex',
		age: 21,
		image: {
			src: ImageAlex,
			alt: 'Persoon met bril op die in een stoel languit een boek leest.',
		},
		biography:
			'Alex is een student. Hij heeft een motorische beperking en kan niet lang staan of lopen. Wanneer hij veel pijn heeft gebruikt hij een rolstoel en een schermlezer met toetsenbordnavigatie.',
		disabilities: [
			{
				category: 'motorisch',
				value: 'spierziekte',
				duration: 'permanent',
				description: 'spierziekte',
			},
		],
		details: [
			{
				id: 'hobby',
				value: 'documentaires kijken en studeren',
			},
			{ id: 'study', value: 'informatica' },
		],
		test: ['focusOutline', 'keyboardNavigation'],
		tools: [
			'wheelchair',
			'screenReaderKeyboard',
			'screenReaderMobileSwipe',
		],
	},
	{
		id: 'rico',
		name: 'Rico',
		age: 31,
		image: {
			src: ImageRico,
			alt: 'Lachend persoon op een portretfoto met kort kroeshaar.',
		},
		biography:
			'Rico houdt van podcasts en tv. Door diens dyslexie heeft die moeite met lezen en schrijven. Ondertiteling is ook niet makkelijk om bij te houden, wanneer er iets in een andere taal wordt gezegd.',
		disabilities: [
			{
				category: 'neurologisch',
				value: 'dyslexie',
				duration: 'permanent',
				description: 'dyslexie',
			},
		],
		details: [
			{
				id: 'hobby',
				value: 'podcasts en tv kijken',
			},
			{
				id: 'morningRitual',
				value: 'de nieuwe aflevering van diens favoriete podcast luisteren',
			},
		],
		test: ['textParsable', 'ownPace'],
		tools: ['textToSpeech', 'spokenSubtitles'],
	},
	{
		id: 'noah',
		name: 'Noah',
		age: 8,
		image: {
			src: ImageNoah,
			alt: 'Persoon met lang rood haar op vakantie in een gebied met besneeuwde bergen.',
		},
		biography:
			'Noah is een fan van tekenfilms en games. Door zijn slechthorendheid heeft hij moeite met het verstaan van dialogen en muziek. Er is veel informatie die hij mist, omdat het niet altijd visueel duidelijk is. Noah is gebarentaal aan het leren, omdat zijn gehoor steeds minder wordt.',
		disabilities: [
			{
				category: 'auditief',
				value: 'slechthorend',
				duration: 'permanent',
				description: 'slechthorendheid',
			},
		],
		details: [
			{
				id: 'hobby',
				value: 'tekenfilms en games',
			},
		],
		test: ['noSound', 'captions'],
		tools: ['captions', 'signLanguage'],
	},
	{
		id: 'jade',
		name: 'Jade',
		age: 25,
		image: {
			src: ImageJade,
			alt: 'Persoon kijkt blij naar een kopje koffie.',
		},
		biography:
			'Jade houdt van muziek en films. Door haar autisme heeft ze moeite met het filteren van prikkels. Jade heeft een vast ritme en houdt van voorspelbaarheid. Ze gebruikt haar scherm met lage helderheid en heeft animaties uitgezet in haar systeeminstellingen.',
		disabilities: [
			{
				category: 'neurologisch',
				value: 'autisme',
				duration: 'permanent',
				description: 'autisme',
			},
		],
		details: [
			{
				id: 'hobby',
				value: 'muziek en films',
			},
			{
				id: 'morningRitual',
				value: 'de dag beginnen met muziek',
			},
		],
		test: ['contrastChecker', 'noAnimations', 'ownPace'],
		tools: ['noAnimations'],
	},
	{
		id: 'vienna',
		name: 'Vienna',
		age: 42,
		image: {
			src: ImageVienna,
			alt: 'Persoon met strakke make-up en twee oorbellen kijkt om naar de camera.',
		},
		biography:
			'Vienna houdt van lezen en muziek. Door haar tinnitus heeft ze moeite met het verstaan van dialogen en muziek. Ze gebruikt ondertiteling voor doven en slechthorenden om alle informatie mee te krijgen. Alleen bestaat ondertiteling vaak nog alleen uit letterlijke gesproken tekst en zijn sprekers onduidelijk, vooral op sociale media.',
		disabilities: [
			{
				category: 'auditief',
				value: 'tinnitus',
				duration: 'permanent',
				description: 'tinnitus (oorsuizen)',
			},
		],
		details: [
			{
				id: 'hobby',
				value: 'lezen en muziek',
			},
			{
				id: 'morningRitual',
				value: 'de dag beginnen met een hoofdstuk uit een boek',
			},
		],
		test: ['noSound', 'captions'],
		tools: ['captions'],
	},
	{
		id: 'tina',
		name: 'Tina',
		age: 19,
		image: {
			src: ImageTina,
			alt: 'Iemand met een zonnebril op poseert met een drankje voor de camera.',
		},
		biography:
			'Tina heeft een tijdelijke beperking. Ze heeft net een hersenschudding gehad en kan niet goed tegen felle lichten en harde geluiden. Ze gaat af en toe al naar buiten met een zonnebril op. De prikkels van het licht van schermen zijn nog te veel. Om toch te kunnen genieten van de dingen die ze altijd dagelijks deed en geen contacten te verliezen, gebruikt ze een schermlezer op haar telefoon met spraakbesturing. Nu kan ze alsnog alles doen.',
		disabilities: [
			{
				category: 'neurologisch',
				value: 'hersenschudding',
				duration: 'tijdelijk',
				description: 'hersenschudding',
			},
		],
		details: [
			{
				id: 'hobby',
				value: 'naar buiten gaan en met vrienden afspreken',
			},
		],
		test: ['keyboardNavigation', 'ownPace'],
		tools: ['screenReaderMobileTouch', 'screenReaderMobileVoice'],
	},
];

export default Personas;
